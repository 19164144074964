

/*//////////////////////////////////////////////////////////////////
[ FONT ]*/


@font-face {
  font-family: Lato-Regular;
  src: url('../../constants/fonts/Lato/Lato-Regular.ttf'); 
}

@font-face {
  font-family: Lato-Bold;
  src: url('../../constants/fonts/Lato/Lato-Bold.ttf'); 
}

/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/


/*//////////////////////////////////////////////////////////////////
[ Table ]*/
table {
  width: 100%;
  height: 100%;
}

th, td {
  font-weight: unset;
  padding-right: 10px;
}

.column1 {
  width: 3%;
  padding-left: 20px;
}


.table100.ver5 .table100-body tr {
  overflow: hidden; 
  border-bottom: 10px solid #fff;
  border-radius: 10px;
}

.table100.ver5 .table100-body table { 
  border-collapse: separate; 
  border-spacing: 0 10px; 
}
.table100.ver5 .table100-body td {
    border: solid 1px transparent;
    border-style: solid none;
    padding-top: 10px;
    padding-bottom: 10px;
}
.table100.ver5 .table100-body td:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px; 
    border-bottom-left-radius: 10px;
}
.table100.ver5 .table100-body td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 10px; 
    border-top-right-radius: 10px; 
}

.table100.ver5 .table100-body th:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px; 
  border-bottom-left-radius: 10px;
}
.table100.ver5 .table100-body th:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px; 
  border-top-right-radius: 10px; 
}

.table100.ver5 .table100-head th {
  padding-top: 15px;
  padding-bottom: 15px;
}

/*---------------------------------------------*/

.table100.ver5 {
  overflow: hidden;
}


.table100.ver5 .ps__rail-y {
  right: 0px;
}

.table100.ver5 .ps__rail-y::before {
  background-color: #ebebeb;
}

.table100.ver5 .ps__rail-y .ps__thumb-y::before {
  background-color: #cccccc;
}
